
























































































import Vue from 'vue';
import firebase from '@/firebase';
import { routerNames } from '@/types/routerNames';

export default Vue.extend({
  data() {
    return {
      email: '',
      emailRules: [
        (v: any) => !!v || 'メールアドレスを入力してください。',
        (v: any) =>
          /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください。',
      ],
      password: '',
      passwordRules: [(v: any) => !!v || 'パスワードを入力してください。'],
      showEye: false,
      loginFailed: false,
      emailToResetPassword: '',
      passwordReset: false,
    };
  },
  methods: {
    refs(): any {
      return this.$refs;
    },
    login() {
      if (!this.refs().loginForm.validate()) {
        return;
      }
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({
            name: routerNames.schedule,
          });
        })
        .catch(() => {
          this.loginFailed = true;
          setTimeout(() => {
            this.loginFailed = false;
          }, 5000);
        });
    },
    resetPassword() {
      if (!this.refs().passwordResetForm.validate()) {
        return;
      }
      firebase
        .auth()
        .sendPasswordResetEmail(this.emailToResetPassword)
        .then(() => {
          alert(
            this.emailToResetPassword +
              ' にパスワード再設定メールを送信しました。'
          );
        })
        .catch((e) => {
          console.error(e);
        });
      this.passwordReset = false;
    },
  },
});
